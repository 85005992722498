import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem.js'
import 'normalize.css/normalize.css';
import { Form, Field, CellGroup, Button, Toast, Overlay, RadioGroup, Radio, Dialog, Pagination,Checkbox, CheckboxGroup} from 'vant';
import 'vant/lib/index.css';

// import VueQrcodeReader from 'vue-qrcode-reader'

// import VueBarcodeScanner from 'vue-barcode-scanner';



const app = createApp(App)
// app.use(VueQrcodeReader)
// app.use(VueBarcodeScanner)
app.use(store).use(router).mount('#app')
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(Toast);
app.use(Overlay);
app.use(Radio);
app.use(RadioGroup);
app.use(Dialog);
app.use(Pagination);
app.use(Checkbox);
app.use(CheckboxGroup);

