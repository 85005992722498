import request from '@/utils/request';
export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data
  })
}
// 找回密码
export function findPassword(data) {
  return request({
    url: "/auth/captcha/findPassword",
    method: "post",
    data
  })
}
// 判断手机号是否存在
export function realMobile(tel) {
  return request({
    url: "/auth/captcha/isRealMobile/" + tel,
    method: "get",
  })
}
// 退出登录
export function logout(params) {
  return request({
    url: "/auth/captcha/logout",
    method: "get",
    params
  })
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: "/auth/captcha/updatePassword",
    method: "post",
    data
  })
}

// 获取验证码
export function getvcode(tel) {
  return request({
    url: "/auth/captcha/vcode/" + tel,
    method: "get",
  })
}
//获取图形验证码
export function getCaptcha() {
  return request({
    url: "/auth/captcha/base64",
    method: "get",
  })
}
