import request from '@/utils/request';
// 账号列表
export function getMenuList(params) {
  return request({
    url: "/api/sys/menu/tree",
    method: "get",
    params
  })
}
// 添加账号
export function menuSave(data) {
  return request({
    url: "/api/sys/menu/save",
    method: "post",
    data
  })
}
// 修改
export function menuUpdate(data) {
  return request({
    url: "/api/sys/menu/update",
    method: "post",
    data
  })
}
// 删除
export function menuDel(id) {
  return request({
    url: `/api/sys/menu/delete/${id}`,
    method: "post"
  })
}
