import axios from 'axios';
// import { validatenull } from './validate';
import { getToken } from '@/utils/auth'; 
import store from '@/store';
import router from '../router/index'
import { showToast } from 'vant';

let baseUrl = '';

const service = axios.create({
  baseURL: baseUrl,
  timeout: 100000
})

service.interceptors.request.use(
  config => {
    console.log('token',store.getters.token)
   if (store.getters.token) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
   }
   console.log(config.url)
   if (config.url === '/user/login') {
    var verifyCode = JSON.parse(localStorage.getItem('code')).verifyCode
    var captcha = JSON.parse(localStorage.getItem('code')).captcha
    console.log(JSON.parse(localStorage.getItem('code')).verifyCode,JSON.parse(localStorage.getItem('code')).captcha)
    config.headers['verifyCode'] = verifyCode 
    config.headers['captcha'] = captcha
   }
   return config
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== 1) {
      console.log(res.msg)
      if(res.code==5001){
        showToast(res.msg || "请求失败，请联系管理员");
        router.push('/')
      }else if(res.code==401){
        showToast(res.msg || "请求失败，请联系管理员");
        router.push('/')
      }else if(res.code==5002){
        showToast(res.msg || "请求失败，请联系管理员");
        router.push('/')
      }else if(res.code==5003){
        showToast(res.msg || "请求失败，请联系管理员");
        router.push('/')
      }else{
        showToast(res.msg || "请求失败，请联系管理员");
      }
    } else {
      return res;
    }
  },
  error => {
    console.log(error);
  }
)
export default service;