import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import { userStore } from '../store/userInfo';
// import { getToken } from '@/utils/auth'; 
// import store from '@/store';
const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/auth/loginView.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/auth/resetPassword')
  },
  {
    path: '/updatePassword',
    name: 'UpdatePassword',
    component: () => import('@/views/auth/updatePassword')
  },
  {
    path: '/api/sys/role/**',
    name: 'Role',
    component: () => import('@/views/role/roleIndex.vue')
  },
  {
    path: '/api/sys/user/**',
    name: 'Account',
    component: () => import('@/views/account/accountIndex.vue')
  },
  {
    path: '/ApplyMigration/**',
    name: 'ApplyMigration',
    component: () => import('@/views/migration/applyMigration.vue')
  },
  {
    path: '/ApplyReset/**',
    name: 'ApplyReset',
    component: () => import('@/views/reset/applyReset.vue')
  },
  {
    path: '/ApplyResetList',
    name: 'ApplyResetList',
    component: () => import('@/views/reset/applyResetList.vue')
  },
  {
    path: '/ApplyMigrationList',
    name: 'ApplyMigrationList',
    component: () => import('@/views/migration/applyMigrationList.vue')
  },
  {
    path: '/adminApplyResetList/**',
    name: 'adminApplyResetList',
    component: () => import('@/views/administratorsReset/applyResetList.vue')
  },
  {
    path: '/adminApplyMigrationList/**',
    name: 'adminApplyMigrationList',
    component: () => import('@/views/administratorsMigration/applyMigrationList.vue')
  },
  {
    path: '/processReset/**',
    name: 'processReset',
    component: () => import('@/views/processReset/applyResetList.vue')
  },
  {
    path: '/processMigration/**',
    name: 'processMigration',
    component: () => import('@/views/processMigration/applyMigrationList.vue')
  },
  {
    path: '/api/sys/dept/**',
    name: 'department',
    component: () => import('@/views/department/departmentIndex.vue')
  },
  {
    path: '/api/sys/deptuser/**',
    name: 'personnel',
    component: () => import('@/views/personnel/personnelIndex.vue')

  },
  {
    path: '/audituser/**',
    name: 'audituser',
    component: () => import('@/views/audituser/audituserIndex.vue')
  },
  {
    path: '/api/sys/menu/**',
    name: 'menuManage',
    component: () => import('@/views/menu/menuManage.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, from, next) => {
//   console.log(to, from)
//   if (to.name !== 'Login') {
//     if (store.getters.token) {
//       next()
//     } else {
//       next('/')
//     }
//   }

// })
export default router
