<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // font-size: 14px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
:root {
  --van-blue: #4261FF !important;
}
.app-container {
  width: 100%;
  background: #F8F8F8;
  min-height: 100vh;
  padding: 32px;
  box-sizing: border-box;
  font-size: 28px;
}
.van-overlay {
  background: rgba(51,51,51, 0.1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn1 {
      width: 180px !important;
      height: 60px;
      background: linear-gradient(136deg, #3FBDFB 0%, #4261FF 100%);
      border-radius: 10px;
      color: #fff;
      border: none;
      font-size: 28px;
    }
    .btn2 {
      width: 180px !important;
      height: 60px;
      background: rgba(66,97,255,0.1);
      border-radius: 10px;
      border: 2px solid #4261FF;
      margin-right: 30px;
      color: #4261FF;
      font-size: 28px;
    }
  //  #qr-shaded-region{
  //   border-width:5px 10px !important;
  //  } 
</style>
