import request from '@/utils/request';
//部门 账号列表
export function getAccountList(params) {
  return request({
    url: "/api/sys/deptuser/page",
    method: "get",
    params
  })
}
// 添加账号
export function userSave(data) {
  return request({
    url: "/api/sys/deptuser/save",
    method: "post",
    data
  })
}

// 更新账号

export function userUpdate(data) {
  return request({
    url: "/api/sys/deptuser/update",
    method: "post",
    data
  })
}
// 删除账号

export function delUser(id) {
  return request({
    url: `/api/sys/deptuser/delete/${id}`,
    method: "post"
  })
}

export function getListRole(data) {
    return request({
      url: "/api/sys/deptuser/listrole",
      method: "get",
      params: data
    })
  }
// 部门列表

  export function getDep() {
    return request({
      url: "/api/sys/deptuser/list",
      method: "get",
    })
  }
// 账号状态
  export function updateStatus(data) {
    return request({
      url: "/api/sys/deptuser/updatestatus",
      method: "post",
      data
    })
  }
