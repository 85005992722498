import { getToken, setToken, removeToken } from '@/utils/auth';
import router, { resetRouter } from '@/router';
import { login, logout } from '@/api/auth';
const state = {
  token: getToken(),
  name: '',
  avatar: '',
  user: {},
  btns: [],
  roles: [],
  info: {}
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_BTNS: (state, btn) => {
    state.btns = btn
  },
  SET_INFO: (state, info) => {
    state.info = info
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const data = response.data
        console.log('登录返回值', data);
        commit('SET_TOKEN', data.token)
        commit('SET_USER', data)
        window.localStorage.setItem('user', JSON.stringify(data));
        setToken(data.token)
        // dispatch('getInfo')
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // updateAvatar({ commit, state }) {
  //   if (state.info.avatar) {
  //     return new Promise((resolve, reject) => {
  //       const param = {
  //         businessCodes: [state.info.avatar],
  //         businessType: "profile-pic"
  //       }
  //       getUploadFileInfo(param).then(result => {
  //         if (result.code === 200) {
  //           const avatar = result.data[0][0].url;
  //           commit('SET_AVATAR', avatar)
  //           resolve(avatar)
  //         } else {
  //           resolve('')
  //           // this.$message({ message: result.message, type: 'warning' })
  //         }
  //       }).catch(error => {
  //         reject(error)
  //       })
  //     })
  //   }
  // },

  // getMenu({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getMenu(state.token).then(response => {
  //       const data = response.data;
  //       const user = JSON.parse(window.localStorage.getItem('user'));
  //       if (user.userType === '2') {
  //         commit('SET_ROLES', ["管理员", "默认"])
  //         resolve(data)
  //       } else {
  //         if (!data) {
  //           reject('用户信息错误！')
  //         }
  //         commit('SET_ROLES', data)
  //         getBtn(state.token).then(response => {
  //           if (response.code === 200) {
  //             const arr = response.data;
  //             commit('SET_BTNS', arr)
  //             resolve(data)
  //           } else {
  //             commit('SET_BTNS', [])
  //             resolve(data)
  //           }
  //         })
  //       }
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // get user info
  // getInfo({ commit, state, dispatch }) {
  //   return new Promise((resolve, reject) => {
  //     const user = JSON.parse(window.localStorage.getItem('user'));
  //     getInfo(user.userCode).then(response => {
  //       const { data } = response
  //       if (!data) {
  //         reject('Verification failed, please Login again.')
  //       }
  //       commit('SET_INFO', data)
  //       if (data.avatar) {
  //         dispatch('updateAvatar')
  //       } else {
  //         commit('SET_AVATAR', '')
  //       }
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  logout({ commit }, params) {
    return new Promise((resolve, reject) => {
      logout(params).then((res) => {
        if (res.code === 1) {
          console.log(12)
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          // resetRouter()
          window.localStorage.removeItem('user');
          
          // window.localStorage.removeItem('staff');
          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          // dispatch('tagsView/delAllViews', null, { root: true })
          resolve()
        }
        
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}